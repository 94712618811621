@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  box-sizing: border-box;
  background: url("./images/IMG_3363.JPG") no-repeat center fixed;
  background-size: cover;
}
html,
body {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  background-color: white;
  border: 7px solid white;
  margin: 0 5px;
}
.swiper-button-prev,
.swiper-button-next {
  color: white;
  /* background-color: white; */
  padding: 25px;
  border-radius: 20px;
}
@keyframes rotateAnimation {
  0% {
    transform: rotate(-15deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

/* Apply the animation to the WhatsApp icon */
.img-animation {
  animation: rotateAnimation 2s infinite;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
